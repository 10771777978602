import React from 'react';
import * as styles from './accountSuccess.module.css';

import ActionCard from '../components/ActionCard';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';

const OrderConfirmPage = (props) => {
  return (
    <Layout disablePaddingBottom>
      <Seo
        title={"Commande"}
        pathname={"/orderConfirm/"}
        description={""}
        banner={""}
        facebbokPixelAdvancedMatching={props?.location?.state?.fbp}
      />
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>Merci!</h1>
          <p>
            Nous traitons actuellement votre commande. Si vous avez des inquiétudes n'hésitez pas
            de <Link to='/contact/'>nous contactez</Link>
          </p>
          <div className={styles.actionContainer}>
            <ActionCard
              title={'Boutique'}
              icon={'bag'}
              subtitle={'Poursuivre vos achats'}
              link={'/shop/'}
            />
            <ActionCard
              title={'Contact'}
              icon={'phone'}
              subtitle={'Contactez-nous'}
              link={'/contact/'}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default OrderConfirmPage;
